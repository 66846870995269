import apiService from '@/services/api.service';

export const CUSTOMER_COMMENTS_LIMIT = 5;
export const CUSTOMER_FIDELITY_VOUCHER_LIMIT = 5;

export default {
  async getAll(page = 1, search = '', limit = 25) {
    const response = await apiService.get(`/customers.json`, {
      limit,
      page,
      search
    });
    return {
      customers: response.data.data,
      pagination: response.data.pagination
    };
  },

  async getByUserIdAndVendorId(userId, vendorId) {
    const response = await apiService.get(
      `/customers/account/${userId}/${vendorId}.json`
    );
    return response.data.data;
  },

  async getById(id) {
    const response = await apiService.get(`/customers/${id}.json`);
    return response.data.data;
  },

  async edit(customer) {
    const response = await apiService.put(
      `/customers/${customer.id}.json`,
      customer
    );
    return response.data.data;
  },

  async add(customer) {
    const response = await apiService.post('/customers.json', customer);
    return response.data.data;
  },
  async verifyDeleteCustomer(customerId) {
    const response = await apiService.post(
      `/customers/verify-delete/${customerId}.json`
    );
    return response.data.data;
  },
  async deleteCustomer(customerId) {
    const response = await apiService.delete(`/customers/${customerId}.json`);
    return response.data.success;
  },
  async addForUser(vendorId) {
    const response = await apiService.post('/customers/add-for-user.json', {
      vendor_id: vendorId
    });
    return response.data.data;
  },

  async getPointsStats(customerId) {
    const response = await apiService.get(`/points/stats/${customerId}.json`);
    return response.data.data;
  },

  async getFidelityVouchersWithBalance(
    customerId,
    page = 1,
    limit = CUSTOMER_FIDELITY_VOUCHER_LIMIT
  ) {
    const response = await apiService.get(
      `/vouchers/fidelity-with-balance/${customerId}.json`,
      {
        page,
        limit
      }
    );
    return {
      vouchers: response.data.data,
      pagination: response.data.pagination
    };
  },

  async getComments(customerId, page = 1, limit = CUSTOMER_COMMENTS_LIMIT) {
    const response = await apiService.get('/comments.json', {
      customer_id: customerId,
      page,
      limit
    });
    return {
      comments: response.data.data,
      pagination: response.data.pagination
    };
  },

  async addComment(customerId, comment) {
    const response = await apiService.post('/comments.json', {
      customer_id: customerId,
      comment
    });
    return response.data.data;
  },

  async editComment(commentId, comment) {
    const response = await apiService.put(`/comments/${commentId}.json`, {
      comment
    });
    return response.data;
  },

  async deleteComment(commentId) {
    return apiService.delete(`/comments/${commentId}.json`);
  },

  async getBookings(open, customerId, page = 1) {
    const response = await apiService.get('/bookings.json', {
      customer_id: customerId,
      page,
      open: open ? 1 : 0
    });
    return {
      bookings: response.data.data,
      pagination: response.data.pagination
    };
  },

  async findBookingById(bookingId, customerId) {
    const response = await apiService.get(
      `/bookings/${bookingId}.json?customer_id=${customerId}`
    );
    return response.data.data;
  },

  async cancelBooking(bookingId) {
    const response = await apiService.delete(
      `/bookings/cancel/${bookingId}.json`
    );
    return response.data.data;
  },

  async deleteBooking(bookingId) {
    const response = await apiService.delete(`/bookings/${bookingId}.json`);
    return response.data.success;
  },

  async getFidelityCard(customerId) {
    const response = await apiService.get(`/cards/${customerId}.json`);
    return response.data.data;
  },

  async merge(customerIdToKeep, customersIdsToDelete, customerData) {
    const response = await apiService.post(
      `/customers/merge/${customerIdToKeep}.json`,
      {
        idsToDelete: customersIdsToDelete,
        ...customerData
      }
    );
    return response.data.data;
  }
};
