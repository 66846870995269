import { Booking } from '@/model/booking';
import { User } from '@/model/user';
import { Voucher } from '@/model/voucher';

export enum PaymentMethod {
  CARD = 'cc/cd', // Carte de crédit et carte de débit hors postcard
  CASH = 'comptant',
  POSTCARD = 'postcard',
  GIFT = 'bon cadeau',
  FIDELITY = 'bon fidelite',
  GENERIC = 'generic'
}

export interface Payment {
  id: number;
  created: string;
  modified: string;
  amount: number;
  method: PaymentMethod;
  user_id: number;
  employee_id: number;
  booking_id: number;
  voucher_uuid: string | null;
  user?: User;
  booking?: Booking;
  voucher?: Voucher;
}
