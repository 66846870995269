









































































































































































































































































































































































































































import RadioInput from '@/components/shared/forms/RadioInput.vue';
import SelectInput from '@/components/shared/forms/SelectInput.vue';
import TextInput from '@/components/shared/forms/TextInput.vue';
import SubmitButton from '@/components/shared/SubmitButton.vue';
import LoadingIndicator from '@/components/shared/LoadingIndicator.vue';
import customerService from '@/services/customer.service';
import cloneDeep from 'lodash/cloneDeep';
import bookingService from '@/services/booking.service';
import DateInput from '@/components/shared/forms/DateInput.vue';
import { API_DATETIME_FORMAT } from '@/constants';
import moment from 'moment';
import voucherService from '@/services/voucher.service';
import BookingFormServices from '@/components/calendar/BookingFormServices.vue';
import Vue from 'vue';
import { mapStores } from 'pinia';
import { BookingsFetchType, useCustomersStore } from '@/store/customers-store';
import { Customer } from '@/model/customer';
import { useLocationsStore } from '@/store/locations-store';
import { BModal } from 'bootstrap-vue';
import { useUiStore } from '@/store/ui-store';
import { Booking } from '@/model/booking';
import { PaymentMethod } from '@/model/payment';
import i18n from '@/config/i18n';
import VueI18n from 'vue-i18n';
import LocaleMessage = VueI18n.LocaleMessage;
import { useEmployeesStore } from '@/store/employees-store';

export default Vue.extend({
  components: {
    RadioInput,
    SelectInput,
    TextInput,
    DateInput,
    SubmitButton,
    LoadingIndicator,
    BookingFormServices
  },
  data() {
    return {
      noShow: null as boolean,
      start: null, // Base de calcul pour tous les bookingsServices start + end
      booking: null as Partial<Booking>,
      title: i18n.t('booking.closing.title'),
      discount_amount: 0,
      savingExpressBooking: false,
      isFetching: true,
      invalidPaymentModalMessage: '',
      fidelityVouchers: [] as any,
      newVoucher: {
        uuid: '',
        amount: 0,
        valid: false,
        note: '',
        textColor: 'light',
        bgColor: 'white',
        balance: 0
      },
      vouchers: [] as any,
      newPayment: { method: '', amount: 0 },
      payments: [] as any,
      locationId: null,
      paymentMethodOptions: [
        { value: PaymentMethod.CARD, label: i18n.t('label.card') },
        { value: PaymentMethod.POSTCARD, label: i18n.t('label.postcard') },
        { value: PaymentMethod.CASH, label: i18n.t('label.cash') }
      ],
      unsubscribeFromStore: null as any
    };
  },
  computed: {
    ...mapStores(
      useUiStore,
      useCustomersStore,
      useLocationsStore,
      useEmployeesStore
    ),
    customer(): Customer {
      return this.customersStore.currentEntity;
    },
    countEmployees(): number {
      return this.employeesStore.entities.length;
    },
    validationErrors(): any {
      return this.customersStore.bookings[BookingsFetchType.OPEN]
        .validationErrors;
    },
    locationsAsSelectOptions(): {} {
      return this.locationsStore.getLocationsAsSelectOptions;
    },
    sendingData(): boolean {
      return (
        this.customersStore.bookings[BookingsFetchType.OPEN].saving ||
        this.savingExpressBooking
      );
    },
    grossTotal(): number {
      if (this.booking.events) {
        return this.booking.events.reduce((amount, event) => {
          return amount + event.service.price;
        }, 0);
      } else {
        return 0;
      }
    },
    netTotal(): number {
      let total = this.grossTotal;
      if (this.discount_amount) {
        total -= this.discount_amount;
      }
      return total;
    },
    balance(): number {
      const paymentSum = this.payments.reduce(function(acc, curr) {
        return acc + parseFloat(curr.amount);
      }, 0);
      const voucherSum = this.vouchers.reduce(function(acc, curr) {
        return acc + parseFloat(curr.amount);
      }, 0);
      const fidelityVoucherSum = this.fidelityVouchers.reduce(function(
        acc,
        curr
      ) {
        return acc + parseFloat(curr.amount);
      },
      0);
      return this.netTotal - paymentSum - voucherSum - fidelityVoucherSum;
    },
    shouldDisplaySaveButton(): boolean {
      if (this.booking.events) {
        return this.booking.events.length > 0;
      } else {
        return false;
      }
    },
    allEffectiveDurationAreSet(): boolean {
      if (this.booking.events) {
        return this.booking.events.some(event => {
          return event.effective_duration;
        });
      } else {
        return false;
      }
    },
    saveButtonLabel(): LocaleMessage | null {
      if (Object.keys(this.booking).length > 0) {
        if (this.noShow) {
          return i18n.t('button.closeBooking');
        } else {
          return this.allEffectiveDurationAreSet
            ? i18n.t('button.closeBooking')
            : i18n.t('button.save');
        }
      } else {
        return null;
      }
    },
    closeDataModelForApi(): {} {
      if (Object.keys(this.booking).length > 0) {
        let lastEndAsMoment = null as any;
        // On crée les paiements pour l'api, soit les paiements effectifs + les bons cadeaux utilisés + les bons de fidélité
        const allPayments = [] as any;
        this.fidelityVouchers.forEach(fidelity => {
          allPayments.push({
            amount: fidelity.amount,
            voucher_uuid: fidelity.uuid,
            method: PaymentMethod.FIDELITY
          });
        });
        this.vouchers.forEach(voucher => {
          allPayments.push({
            amount: voucher.amount,
            voucher_uuid: voucher.uuid,
            method: PaymentMethod.GIFT
          });
        });
        this.payments.forEach(payment => {
          allPayments.push(payment);
        });
        return {
          no_show: this.noShow,
          discount_amount: this.discount_amount,
          total: this.grossTotal,
          payments: allPayments.filter(payment => {
            return payment.amount > 0;
          }),
          events: this.booking.events.map((event, eventIndex) => {
            let eventStartAsMoment = null as any;
            if (eventIndex === 0) {
              eventStartAsMoment = moment(this.start);
            } else {
              eventStartAsMoment = lastEndAsMoment.clone();
            }
            const eventEndAsMoment = eventStartAsMoment
              .clone()
              .add(
                parseInt((event.effective_duration as any) as string),
                'minutes'
              );
            lastEndAsMoment = eventEndAsMoment;
            return {
              id: event.id !== undefined ? event.id : null,
              start: eventStartAsMoment.format(API_DATETIME_FORMAT),
              end: eventEndAsMoment.format(API_DATETIME_FORMAT),
              customer_start: eventStartAsMoment.format(API_DATETIME_FORMAT),
              customer_end: eventEndAsMoment.format(API_DATETIME_FORMAT),
              service_id: event.service_id,
              effective_duration: event.effective_duration,
              workplace_id: event.workplace_id,
              employee_id: event.employee_id,
              vendor_id: event.vendor_id,
              manual: true
            };
          })
        };
      } else {
        return {};
      }
    },
    expressBooking(): boolean {
      return !this.booking || !this.booking.id;
    }
  },
  destroyed() {
    if (this.unsubscribeFromStore) {
      this.unsubscribeFromStore();
    }
  },
  async created() {
    if (!this.customer.id) {
      await this.customersStore.getCustomer(this.$route.params.customerId);
    }
    if (this.$route.query.close) {
      this.title = 'Modifier la clôture';
    }
    if (this.$route.query.bookingId) {
      if (this.booking) {
        this.start = this.booking.start;
      } else {
        this.booking = await this.customersStore.findBookingById(
          this.$route.query.bookingId as string,
          this.$route.params.customerId
        );
        this.start = this.booking.start;
      }
    } else {
      this.booking = {
        no_show: null,
        discount_amount: 0,
        events: []
      };
    }
    await this.setupForm();
  },
  methods: {
    checkDiscountAmount() {},
    setValidVoucherBgColor(validity) {
      return this.isValid(validity) ? 'success' : 'warning';
    },
    setValidVoucherNote(validity, balance) {
      let validText = '';
      if (validity === null) {
        validText = 'Valable';
      } else {
        validText =
          (this.isValid(validity)
            ? i18n.t('voucher.validUntil') + ' '
            : i18n.t('voucher.notValidSince') + ' ') +
          moment(validity).format('DD.MM.YY');
      }
      return `${validText}. ` + i18n.t('voucher.balance') + ` CHF ${balance}.`;
    },
    isValid(validity) {
      return validity === null || moment(validity).diff(moment(), 'days') > 0;
    },
    fidelityVoucherMap(fidelity) {
      const textColor = 'light';
      let bgColor = 'success';
      let text = i18n.t('label.valid');
      let noteFidelity =
        i18n.t('voucher.validUntil') +
        ` ${moment(fidelity.validity).format('DD.MM.YY')}. `;
      const noteAmount = i18n.t('voucher.balance') + ` CHF ${fidelity.balance}`;

      if (moment(fidelity.validity).isBefore(moment())) {
        bgColor = 'warning';
        text = 'Expiré';
        noteFidelity =
          i18n.t('voucher.notValidSince') +
          ` ${moment(fidelity.validity).format('DD.MM.YY')}. `;
      }
      return {
        ...fidelity,
        amount: 0 + '',
        textColor: textColor,
        bgColor: bgColor,
        text: text,
        noteFidelity: noteFidelity,
        noteAmount: noteAmount
      };
    },
    async setupForm() {
      if (this.booking.events && this.booking.events.length > 0) {
        this.locationId = this.booking.events[0].location_id;
      }
      if (this.booking.no_show !== null) {
        this.noShow = this.booking.no_show;
      }
      this.discount_amount = this.booking.discount_amount;
      // On place les bons de fidelité disponibles dans la select box
      this.fidelityVouchers = (
        await customerService.getFidelityVouchersWithBalance(
          this.customer.id,
          1,
          0
        )
      ).vouchers.map(this.fidelityVoucherMap);
      // On replace les paiements et on sépare les bons cadeaux en les remettant en forme
      if (
        this.booking.payments !== undefined &&
        this.booking.payments.length > 0
      ) {
        // On ne peut pas utiliser forEach avec await
        for (let i = 0; i < this.booking.payments.length; i++) {
          const payment = this.booking.payments[i];
          if (
            payment.voucher_uuid !== null &&
            payment.method === PaymentMethod.GIFT
          ) {
            const voucherFromAPi = await voucherService.verify(
              payment.voucher_uuid
            );
            const balance = voucherFromAPi.balance + payment.amount;
            this.vouchers.push({
              uuid: payment.voucher_uuid,
              amount: payment.amount,
              balance: balance,
              valid: true,
              note: this.setValidVoucherNote(voucherFromAPi.validity, balance),
              textColor: 'white',
              bgColor: this.setValidVoucherBgColor(voucherFromAPi.validity)
            });
          } else if (
            payment.voucher_uuid !== null &&
            payment.method === PaymentMethod.FIDELITY
          ) {
            let index = this.fidelityVouchers.findIndex(
              fidelity => fidelity.uuid === payment.voucher_uuid
            );
            if (index === -1) {
              let fidelity = await voucherService.getFidelity(
                payment.voucher_uuid
              );
              fidelity = this.fidelityVoucherMap(fidelity);
              const length = this.fidelityVouchers.push(fidelity);
              index = length - 1;
            }
            this.fidelityVouchers[index].amount = payment.amount;
            this.fidelityVouchers[index].balance += payment.amount;
            this.fidelityVouchers[
              index
            ].noteAmount = `Solde de CHF ${this.fidelityVouchers[index].balance}`;
          } else {
            /**
             *  On ne garde que les informations essentielles du paiement. Il sera remplacé par un
             *  nouveau paiement en bdd.
             *  Il faut le faire car les paiements sont partagés avec les bons cadeaux.
             */
            this.payments.push({
              amount: payment.amount,
              method: payment.method
            });
          }
        }
      }
      // On prépare la possibilité d'ajouter un paiement
      this.vouchers.push(cloneDeep(this.newVoucher));
      this.payments.push(this.newPayment);
      this.isFetching = false;
    },
    checkAmount(amount, max = -1) {
      if (max >= 0) {
        amount = amount > max ? max : amount;
      }
      return isNaN(amount) || amount === '' || amount < 0 ? 0 : amount;
    },
    addPayment(value, index) {
      if (this.payments[index].method === value) return;
      if (value !== '') {
        this.payments[index].method = value;
        if (this.payments.length === index + 1) {
          this.payments.push({ method: '', amount: 0 });
        }
      } else {
        if (this.payments.length > 1) {
          this.payments.splice(index, 1);
        }
      }
    },
    deletePayment(index) {
      this.payments.splice(index, 1);
    },
    async checkVoucher(index) {
      // Remise des paramètres à 0
      this.vouchers[index].valid = false;
      this.vouchers[index].note = '';
      this.vouchers[index].textColor = 'light';
      this.vouchers[index].bgColor = 'white';

      const uuid = this.vouchers[index].uuid;

      // Vérifier qu'il n'est pas déjà selectionné
      const foundVoucher = this.vouchers.find((voucher, voucherIndex) => {
        return voucher.uuid === uuid && voucherIndex !== index;
      });

      if (foundVoucher !== undefined) {
        // Déjà utiliser dans la clôture
        this.vouchers[index].note = 'Bon déjà utilisé dans cette clôture !';
        this.vouchers[index].textColor = 'danger';
        this.vouchers[index].bgColor = 'danger';
      } else if (uuid.length > 8) {
        // Trop long
        this.vouchers[index].note = 'Cet identifiant est trop long !';
        this.vouchers[index].textColor = 'danger';
        this.vouchers[index].bgColor = 'danger';
      } else if (uuid.length === 8) {
        // On vérifie sur le serveur
        try {
          const voucherFromAPi = await voucherService.verify(uuid);
          this.vouchers[index].balance = voucherFromAPi.balance;
          this.vouchers[index].valid = true;
          this.vouchers[index].note = this.setValidVoucherNote(
            voucherFromAPi.validity,
            voucherFromAPi.balance
          );
          this.vouchers[index].textColor = 'white';
          this.vouchers[index].bgColor = this.setValidVoucherBgColor(
            voucherFromAPi.validity
          );
          this.vouchers.push(cloneDeep(this.newVoucher));
        } catch (exception) {
          this.vouchers[index].note = exception.message;
          this.vouchers[index].textColor = 'danger';
          this.vouchers[index].bgColor = 'danger';
        }
      }
    },
    deleteVoucher(index) {
      this.vouchers.splice(index, 1);
    },
    getValidationErrorsForVoucher(index) {
      // Il n'y pas d'erreur sur les paiements effectifs
      return this.validationErrors.payments &&
        this.validationErrors.payments[index] !== undefined
        ? this.validationErrors.payments[index]
        : {};
    },
    hideInvalidPaymentModal() {
      (this.$refs.invalidPaymentsModal as BModal).hide();
    },
    async onSubmit(checkPayment = true) {
      const invalidPayments = this.payments.filter(function(payment) {
        return payment.amount <= 0 && payment.method !== '';
      });
      if (invalidPayments.length > 0 && checkPayment) {
        this.invalidPaymentModalMessage =
          "Voulez-vous vérifier les paiements ? Il semble qu'il y ait des montants nuls. Si vous ignorez, les paiements ne seront pas pris en compte.";
        (this.$refs.invalidPaymentsModal as BModal).show();
      } else {
        if (this.expressBooking) {
          try {
            this.savingExpressBooking = true;
            await bookingService.addAndCloseExpress(
              this.customer.id,
              this.closeDataModelForApi
            );
            this.customersStore.bookings[
              BookingsFetchType.OPEN
            ].failure = false;

            this.customersStore.bookings[
              BookingsFetchType.OPEN
            ].validationErrors = {};

            this.savingExpressBooking = false;
            await this.$router.push({
              name: 'CustomersView',
              params: { id: (this.customer.id as unknown) as string }
            });
          } catch (exception) {
            this.uiStore.alert(exception.message);

            if (exception.errors !== undefined) {
              this.customersStore.bookings[
                BookingsFetchType.OPEN
              ].failure = true;

              this.customersStore.bookings[
                BookingsFetchType.OPEN
              ].validationErrors = exception.errors;
            }
            this.savingExpressBooking = false;
          }
        } else {
          await this.customersStore.closeBooking(
            BookingsFetchType.OPEN,
            this.booking.id,
            this.closeDataModelForApi,
            this.customer.id
          );
        }
      }
    }
  }
});
