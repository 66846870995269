import apiService from '@/services/api.service';

export default {
  async getAll(page, search = '') {
    const response = await apiService.get(
      `/vouchers.json?limit=25&page=${page}&search=${search}`
    );
    return {
      vouchers: response.data.data,
      pagination: response.data.pagination
    };
  },

  async getFidelity(uuid) {
    const response = await apiService.post('/vouchers/get-fidelity.json', {
      uuid: uuid
    });
    return response.data.data;
  },

  async add(voucher) {
    const response = await apiService.post('/vouchers.json', voucher);
    return response.data.data;
  },

  async delete(uuid) {
    const response = await apiService.post('/vouchers/delete.json', {
      uuid: uuid
    });
    return response.data.data;
  },

  async verify(uuid) {
    const response = await apiService.post('/vouchers/verify.json', {
      uuid: uuid
    });
    return response.data.data;
  }
};
