var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dashboard-content"},[_c('div',{staticClass:"dashboard-header"},[_c('div',{staticClass:"dashboard-header-title"},[(_vm.expressBooking)?_c('span',[_vm._v("Clôturer RDV sans réservation")]):_c('span',[_vm._v(_vm._s(_vm.title))])])]),_c('div',{staticClass:"dashboard-card"},[(_vm.isFetching)?_c('loading-indicator',{attrs:{"margin-top":true}}):_c('form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-2"},[_c('strong',[_vm._v(_vm._s(_vm.$t('booking.closing.show')))])]),_c('div',{staticClass:"col-md-10"},[_c('radio-input',{attrs:{"default-value":_vm.noShow,"errors":_vm.validationErrors,"value-data-type":"boolean","options":[
              { value: '0', label: _vm.$t('label.yes') },
              { value: '1', label: _vm.$t('label.no') }
            ],"inline":true,"name":"no_show","label":""},on:{"onValueChange":function (value) {
                _vm.noShow = value;
              }}})],1)]),(_vm.expressBooking)?_c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-2 pt-2"},[_c('strong',[_vm._v(_vm._s(_vm.$t('booking.closing.start')))])]),_c('div',{staticClass:"col-lg-3 col-md-4"},[_c('date-input',{attrs:{"value":_vm.start,"name":"start","label":"","enable-time":true},on:{"onValueChange":function (value) {
                  _vm.start = value;
                }}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-2 pt-2"},[_c('strong',[_vm._v(_vm._s(_vm.$t('label.location')))])]),_c('div',{staticClass:"col-lg-3 col-md-4"},[_c('select-input',{attrs:{"options":_vm.locationsAsSelectOptions,"value":_vm.locationId,"name":"location_id","rules":"required"},on:{"onValueChange":function (value) {
                  _vm.locationId = parseInt(value, 10);
                }}})],1)])]):_vm._e(),(_vm.booking.events)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-2 pt-2"},[(_vm.booking.events.length === 1)?_c('strong',[_vm._v("Soin")]):_vm._e(),(_vm.booking.events.length > 1)?_c('strong',[_vm._v("Soins")]):_vm._e()]),_c('div',{staticClass:"col-lg-10"},[_c('booking-form-services',{attrs:{"events":_vm.booking.events,"location-id":_vm.locationId,"employee-id":_vm.countEmployees === 1 ? _vm.booking.events[0].employee_id : null,"validation-errors":_vm.validationErrors,"closing-form":true,"customer-id":_vm.customer.id},on:{"update":function($event){_vm.booking.events = $event}}})],1)]):_vm._e(),_c('div',{staticClass:"row align-items-center mb-3"},[_c('div',{staticClass:"col-md-2 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('booking.closing.price'))+" ")]),_c('div',{staticClass:"col-md-10"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-3 offset-md-4"},[_c('text-input',{attrs:{"id":"gross_total","value":_vm.grossTotal + '',"errors":_vm.validationErrors,"disabled":true,"name":"gross_total","label":"","type":"text","prepend-text":"CHF"}})],1)])])]),_c('div',{staticClass:"row align-items-center mb-3"},[_c('div',{staticClass:"col-md-2"},[_c('strong',[_vm._v(_vm._s(_vm.$t('booking.closing.discount')))])]),_c('div',{staticClass:"col-md-10"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-3 offset-md-4"},[_c('text-input',{attrs:{"id":"discount_amount","value":_vm.discount_amount + '',"errors":_vm.validationErrors,"name":"discount_amount","label":"","type":"text","prepend-text":"CHF"},on:{"onValueChange":function (value) {
                    _vm.discount_amount = value;
                  },"blur":function($event){return _vm.checkDiscountAmount()}}})],1)])])]),_c('div',{staticClass:"row align-items-center mb-3"},[_c('div',{staticClass:"col-md-2 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('booking.closing.total'))+" ")]),_c('div',{staticClass:"col-md-10"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-3 offset-md-4"},[_c('text-input',{attrs:{"id":"total","value":_vm.netTotal + '',"errors":_vm.validationErrors,"disabled":true,"name":"total","label":"","type":"text","prepend-text":"CHF"}})],1)])])]),(_vm.netTotal > 0 && _vm.fidelityVouchers.length > 0)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-2 pt-2 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('booking.closing.fidelityVoucher'))+" ")]),_c('div',{staticClass:"col-md-10"},_vm._l((_vm.fidelityVouchers),function(fidelity){return _c('div',{key:fidelity.id,staticClass:"mb-3 row align-items-center"},[_c('div',{staticClass:"col-md-4",class:fidelity.note !== '' ? 'mb-3' : ''},[_c('text-input',{attrs:{"name":"uuid","name-prefix":fidelity.id + '_',"value":fidelity.uuid,"label":"","append-text":fidelity.text,"disabled":true,"append-text-color":fidelity.textColor,"append-text-bg-color":fidelity.bgColor,"type":"text"}}),(fidelity.noteFidelity !== '')?_c('p',{staticClass:"small position-absolute",class:'text-' + fidelity.bgColor},[_vm._v(" "+_vm._s(fidelity.noteFidelity)+" "+_vm._s(fidelity.noteAmount)+" ")]):_vm._e()],1),_c('div',{staticClass:"col-md-3",class:fidelity.note !== '' ? 'mb-3' : ''},[_c('text-input',{attrs:{"value":fidelity.amount + '',"name":"fidelityAmount","name-prefix":fidelity.id + '_',"label":"","type":"text","prepend-text":"CHF"},on:{"onValueChange":function($event){[
                    (fidelity.amount = +$event),
                    (fidelity.amount = _vm.checkAmount(
                      fidelity.amount,
                      fidelity.balance
                    ))
                  ]}}})],1),_c('div',{staticClass:"col-md-1",class:fidelity.note !== '' ? 'mb-3' : ''})])}),0)]):_vm._e(),(_vm.netTotal > 0)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-2 pt-2 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('booking.closing.giftCards'))+" ")]),_c('div',{staticClass:"col-md-10"},_vm._l((_vm.vouchers),function(voucher,index){return _c('div',{key:voucher.id,staticClass:"mb-3 row align-items-center"},[_c('div',{staticClass:"col-md-4",class:voucher.note !== '' ? 'mb-3' : ''},[_c('text-input',{attrs:{"name":"uuid","name-prefix":voucher.id + '_',"value":voucher.uuid,"label":"","append-text":_vm.$t('label.verified'),"disabled":voucher.valid,"append-text-color":voucher.textColor,"append-text-bg-color":voucher.bgColor,"type":"text"},on:{"onValueChange":function($event){[(voucher.uuid = $event.toUpperCase()), _vm.checkVoucher(index)]}}}),(voucher.note !== '')?_c('p',{staticClass:"small position-absolute",class:'text-' + voucher.bgColor},[_vm._v(" "+_vm._s(voucher.note)+" ")]):_vm._e()],1),_c('div',{staticClass:"col-md-3",class:voucher.note !== '' ? 'mb-3' : ''},[(_vm.vouchers[index].valid)?_c('text-input',{attrs:{"value":voucher.amount + '',"errors":_vm.getValidationErrorsForVoucher(index),"name":"voucherAmount","name-prefix":voucher.id + '_',"label":"","type":"text","prepend-text":"CHF"},on:{"onValueChange":function($event){[
                    (voucher.amount = +$event),
                    (voucher.amount = _vm.checkAmount(
                      voucher.amount,
                      voucher.balance
                    ))
                  ]}}}):_vm._e()],1),_c('div',{staticClass:"col-md-1",class:voucher.note !== '' ? 'mb-3' : ''},[(_vm.vouchers[index].valid)?_c('i',{staticClass:"fas fa-trash pointer text-danger ",on:{"click":function($event){return _vm.deleteVoucher(index)}}}):_vm._e()])])}),0)]):_vm._e(),(_vm.netTotal > 0)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-2 pt-2 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('booking.closing.payments'))+" ")]),_c('div',{staticClass:"col-md-10"},_vm._l((_vm.payments),function(payment,index){return _c('div',{key:payment.id,staticClass:"mb-3 row align-items-center"},[_c('div',{staticClass:"col-md-4"},[_c('select-input',{attrs:{"options":_vm.paymentMethodOptions,"value":payment.method,"disabled":_vm.payments[index].amount > 0,"name":"method","name-prefix":payment.id + '_',"label":""},on:{"onValueChange":function (value) {
                    _vm.addPayment(value, index);
                  }}})],1),_c('div',{staticClass:"col-md-3"},[(_vm.payments[index].method !== '')?_c('text-input',{attrs:{"value":payment.amount + '',"name":"amount","name-prefix":payment.id + '_',"label":"","type":"text","prepend-text":"CHF"},on:{"onValueChange":function($event){[
                    (payment.amount = +$event),
                    (payment.amount = _vm.checkAmount(payment.amount))
                  ]}}}):_vm._e()],1),_c('div',{staticClass:"col-md-1"},[(_vm.payments[index].method !== '')?_c('i',{staticClass:"fas fa-trash pointer text-danger ",on:{"click":function($event){return _vm.deletePayment(index)}}}):_vm._e()])])}),0)]):_vm._e(),_c('div',{staticClass:"row align-items-center mb-3"},[_c('div',{staticClass:"col-md-2 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('booking.closing.net'))+" ")]),_c('div',{staticClass:"col-md-10"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-3 offset-md-4"},[_c('text-input',{attrs:{"id":"balance","value":_vm.balance + '',"errors":_vm.validationErrors,"disabled":true,"name":"balance","label":"","type":"text","prepend-text":"CHF"}})],1)])])]),(_vm.shouldDisplaySaveButton)?_c('div',{staticClass:"tw-flex tw-justify-end tw-gap-4"},[_c('router-link',{staticClass:"btn btn-secondary",attrs:{"to":{ name: 'CustomersView', params: { id: _vm.customer.id } },"tag":"button"}},[_vm._v(" "+_vm._s(_vm.$t('button.cancel'))+" ")]),_c('submit-button',{attrs:{"label":_vm.saveButtonLabel,"loading":_vm.sendingData}})],1):_vm._e()])],1),_c('b-modal',{ref:"invalidPaymentsModal",attrs:{"title":"Attention","cancel-title":"Ignorer","cancel-variant":"outline-warning","ok-title":"Vérifier","ok-variant":"dark","centered":""},on:{"ok":_vm.hideInvalidPaymentModal,"cancel":function($event){return _vm.onSubmit(false)}}},[_vm._v(" "+_vm._s(_vm.invalidPaymentModalMessage)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }